/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.title {
    width: 100%;
    font-weight: bold;
}

.lineLabel {
    width: 33%;
    display: flex;
    align-items: center;
    padding-top: 15px;
    justify-content: flex-end;
    padding-right: 8px;
}

.valueLabel {
    width: 33%;
    display: flex;
    align-items: center;
    padding-top: 15px;
    justify-content: flex-start;
    padding-right: 8px;
}

.studentDetailsFramesTrash {
}

    .studentDetailsFramesTrash:hover {
        background-color: #ff000020;
        cursor: pointer;
    }

.miniInput {
    width: 100px !important;
    margin-left: 10px;
}

.delete {
    background-color: #ee0000 !important;
    color: #fff !important;
    font-size: 15px !important;
}

    .delete:hover {
        background-color: #cc0000 !important;
    }

    .delete:active, .delete:focus {
        outline: none !important;
    }

.smallLabel {
    width: 50px !important;
}

.scLabel {
    width: 75px !important;
}

.centerCheckBoxTD {
    text-align: center;
    vertical-align: middle;
}

.centerCheckBoxInput {
    position: unset;
}

.rowSpace {
    margin-top: 10px;
}

.hideInput {
    visibility: hidden;
}

select:disabled {
    opacity: 0.6;
    pointer-events: none;
}

.showHideTable {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #eee;
    margin-bottom: 5px;
}

    .showHideTable td:first-child {
        text-align: right;
        font-weight: bold;
        vertical-align: middle;
        align-items: center;
    }

    .showHideTable td {
        font-size: 15px;
        padding: 0 3px 3px 3px;
        vertical-align: middle;
        align-items: center;
    }

        .showHideTable td input[type=checkbox] {
            margin: 0 10px 0 8px;
            transform: scale(1.4);
        }

.showHideContainer {
    position: relative;
    width: fit-content;
    min-width: 100%;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.studentForms {
    width: fit-content;
    border: 1px solid #ccc;
    border-collapse: collapse;
}

    .studentForms th {
        font-size: 16px;
        font-weight: bold;
        background-color: #00000008;
        border: 1px solid #eee;
        padding: 3px 7px;
        text-align: left;
        vertical-align: middle;
    }

    .studentForms td {
        font-size: 15px;
        padding: 3px 7px;
        border: 1px solid #eee;
        text-align: left;
        vertical-align: middle;
    }

    .studentForms tr:nth-child(even) {
        background-color: #00000008;
    }

    .studentForms td label {
        width: 100%;
        text-align: center;
    }

.grid-btn {
    width: 80px !important;
    font-size: 13px !important;
    padding: 4px 7px !important;
    background-color: #0069d9 !important;
    border-color: #0062cc !important;
    color: #fff !important;
    white-space: nowrap !important;
}
