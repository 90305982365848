* {
    font-size: 14px;
    font-weight: 400;
}

.headerAndLogo {
    display: none;
}

.print-show {
    visibility: hidden;
    height: 1px;
}

@media print {
    * {
        font-size: 12px;
        font-weight: 400;
    }

    .btn {
        display: none !important;
    }

    .static-width-6vw {
        width: fit-content !important;
    }

    select.form-control {
        width: fit-content !important;
    }

    .headerAndLogo {
        display: inherit;
    }

    textarea {
        min-width: 300px !important;
        min-height: 100px !important;
        max-height: 100px !important;
    }

    .print-hide {
        display: none !important;
    }

    .print-show {
        visibility: visible;
        height: 175px;
    }

    .gridTable thead tr {
        background-color: #0074c8 !important;
        -webkit-print-color-adjust: exact !important;
    }
}

.container {
    max-width: 80vw !important;
    /*max-width: 1200px !important;*/
}

@media (min-width: 1200px) {
    .container {
        max-width: 80vw !important;
        /*width: 1200px !important;*/
    }
}

.btn-primary {
    background-color: #0074c8 !important;
    white-space: nowrap;
}

.btn-20-right {
    margin-right: 20px;
}

.btn-float-right {
    float: right;
}

.col-left-20 {
    margin-left: 20px;
}

.padding-10 {
    padding: 10px;
}

.container-main {
    margin-top: 50px;
}

.ul-link {
    font-size: 24px;
}

.little-button {
    color: #fff;
    background-color: #35B96D;
    border-color: #35B96D;
    border-radius: 0.25rem;
    font-size: 12px;
    font-weight: 400;
    padding: 3px 5px 3px 5px;
    cursor: pointer;
    margin-left: 7px;
    white-space: nowrap;
    filter: drop-shadow(1px 1px 2px #49505750);
}

.btn {
    filter: drop-shadow(2px 2px 4px #49505750);
}

.radio-group {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: 15px 0 15px 0;
}

    .radio-group div {
        align-items: center;
        margin-bottom: 5px;
    }

        .radio-group div > label {
            vertical-align: middle;
            margin-bottom: 0;
            margin-left: 5px;
        }

        .radio-group div > input {
            vertical-align: middle;
            transform: scale(1.3);
        }

.row-20 {
    margin-top: 20px;
}

.row-10 {
    margin-top: 10px;
}

.row-item {
    width: 100%;
    padding: 5px;
}

.card-item {
    width: 100%;
}

.sort-button {
    border-radius: 50%;
    background-color: transparent;
    border-color: transparent;
    vertical-align: middle;
    margin-left: 10px;
}

    .sort-button:hover {
        border-radius: 50%;
        background-color: lightgray;
        border-color: darkgray;
        vertical-align: middle;
        margin-left: 10px;
    }

.menuDropdown {
    background-color: transparent;
    color: #000;
    border: 0;
    font-weight: 600;
    padding: 0 10px 0 5px !important;
}

    .menuDropdown:focus, .menuDropdown:active, .menuDropdown:hover, .menuDropdown:before {
        outline: none;
        background-color: transparent !important;
        color: #000 !important;
        /*border-width: 0;*/
        border-color: transparent !important;
        box-shadow: 0 0 0 0.2rem rgb(130 138 145 / 0%) !important;
        padding: 0 10px 0 5px !important;
    }

.et-menu-item {
    color: #000;
    font-weight: 600;
}

.menu-items {
    display: flex;
    flex-direction: row;
    justify-self: flex-start;
    width: 50%;
}

.profile-logout {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 50%;
}

.menu-main {
    width: 100%;
}

.min-width-button {
    width: 4.6vw !important;
    min-width: 88px !important;
}

.min-width-textinput {
    width: 15vw !important;
    min-width: 250px !important;
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    width: 100%;
}


.form-control, .css-b62m3t-container {
    /*margin-top: 10px;*/
}

.absolute-center {
    display: flex;
    justify-content: center;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.row-label-input {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.row-label {
    width: 52%;
    display: flex;
    align-items: center;
    /*padding-top: 5px;*/
    justify-content: flex-end;
    margin-right: 5px;
    white-space: nowrap;
}

.row-label-roster {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 8px;
}

.row-label-0 {
    display: flex;
    align-items: center;
    /*padding-top: 6px;*/
    justify-content: flex-end;
    padding-right: 8px;
    white-space: nowrap;
}

.row-label-upload {
    width: 8em;
    display: flex;
    align-items: center;
    /*padding-top: 6px;*/
    justify-content: flex-end;
    padding-right: 8px;
}

.row-label-textarea {
    width: 52%;
    display: flex;
    align-items: flex-start;
    padding-top: 5px;
    justify-content: flex-end;
    padding-right: 8px;
}

.row-label-textarea-upload {
    width: 7em;
    display: flex;
    align-items: flex-start;
    padding-top: 5px;
    justify-content: flex-end;
    padding-right: 8px;
}

.form-check-input {
    position: relative !important;
    margin-left: 0 !important;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: inherit;
    font-weight: inherit;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.static-width-6vw {
    width: 6.5vw !important;
}

.static-width-10vw {
    width: 10vw !important;
}

.static-width-15vw {
    width: 15vw !important;
}

.static-width-20vw {
    width: 20vw !important;
}

.width-fit-content {
    width: fit-content !important;
}

.btn-secondary {
    color: #000 !important;
    background-color: #00000000 !important;
    border-color: #00000000 !important;
    font-weight: inherit !important;
    font-size: inherit !important;
}

.btn {
    display: inline-block;
    font-weight: inherit;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.305rem 0.75rem;
    font-size: inherit;
    line-height: 1.25;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.toast {
    width: fit-content;
    background-color: #ddffd4 !important;
    padding: 5px;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px !important;
    position: fixed;
    top: 12%;
    left: 45%;
    /*transform: translate(-50%, -50%);*/
    z-index: 9999;
    -webkit-animation: fadein 500ms; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 500ms; /* Firefox < 16 */
    -ms-animation: fadein 500ms; /* Internet Explorer */
    -o-animation: fadein 500ms; /* Opera < 12.1 */
    animation: fadein 500ms;
    border: 1px solid #1eb101;
}

.toast-body {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #000 !important;
    font-size: 15px;
    font-weight: 400;
    /*text-rendering: optimizeLegibility;*/
    z-index: 10000;
}

.toast-error {
    width: fit-content;
    background-color: #ff000030 !important;
    padding: 5px;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px !important;
    position: sticky;
    top: 12%;
    left: 42%;
    /*transform: translate(-50%, -50%);*/
    z-index: 9999;
    -webkit-animation: fadein 500ms; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 500ms; /* Firefox < 16 */
    -ms-animation: fadein 500ms; /* Internet Explorer */
    -o-animation: fadein 500ms; /* Opera < 12.1 */
    animation: fadein 500ms;
    border: 1px solid #ff0000 !important;
}

.margin-left-5 {
    margin-left: 5px;
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.popout {
    width: 300px;
    background-color: #ddffd4;
    padding: 5px;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 17%;
    left: 50%;
    z-index: 9999;
    animation: popout 1s ease;
    -webkit-animation: popout 1s ease;
}

body::-webkit-scrollbar {
    width: .95em;
    height: .95em;
}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
}

body::-webkit-scrollbar-thumb {
    background-color: rgb(0, 0, 0, 0.2);
    border-radius: 5px;
    width: 0.75em;
    height: 0.75em;
}

div::-webkit-scrollbar {
    background-color: #fff;
    width: .95em;
    height: .95em;
}

div::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
}

div::-webkit-scrollbar-thumb {
    background-color: rgb(0, 0, 0, 0.2);
    border-radius: 5px;
    width: 0.75em;
    height: 0.75em;
}

@keyframes popout {
    from {
        transform: scale(0)
    }

    80% {
        transform: scale(1.2)
    }

    to {
        transform: scale(1)
    }
}

@-webkit-keyframes popout {
    from {
        -webkit-transform: scale(0)
    }

    80% {
        -webkit-transform: scale(1.2)
    }

    to {
        -webkit-transform: scale(1)
    }
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-5 {
    margin-top: 5px;
}



.max-height-15vh-overflow {
    max-height: 15vh;
    overflow: auto;
}

.healthHistoryTable {
    height: fit-content;
}

    .healthHistoryTable tbody td {
        white-space: nowrap;
        padding: 3px 7px;
    }

.gridTable {
    width: 100%;
    height: fit-content;
}

    .gridTable thead tr {
        background-color: #0074c8 !important;
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important;
    }

    .gridTable thead th {
        color: #fff;
        font-weight: 400;
        width: fit-content;
        padding: 3px 7px;
        white-space: nowrap;
    }

        .gridTable thead th:first-child {
            border-radius: 5px 0 0 0;
        }

        .gridTable thead th:last-child {
            border-radius: 0 5px 0 0;
        }

        .gridTable thead th:not(:first-child), gridTable thead th:not(:last-child) {
            border-left: 1px solid #eee;
        }

    .gridTable tbody td:last-child {
        border-right: 1px solid #eee;
    }

    .gridTable tbody tr:last-child {
        border-bottom: 1px solid #eee;
    }

    .gridTable tbody tr:hover {
        cursor: pointer;
        background-color: #99999925;
    }

    .gridTable tbody tr:nth-child(odd) {
        background-color: #00000008;
    }

    .gridTable tbody td:first-child {
        white-space: nowrap;
        padding: 3px 7px;
        border-left: 1px solid #eee;
    }

    .gridTable tbody td:not(:first-child) {
        padding: 3px 7px;
        white-space: nowrap;
        border-left: 1px solid #eee;
    }

    .gridTable tbody tr {
        border-top: 1px solid #eee;
        width: fit-content;
    }

    .gridTable tbody td {
        width: fit-content;
    }

.gridTable2 {
    width: 100%;
    height: fit-content;
}

    .gridTable2 thead tr {
        background-color: #0074c8 !important;
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important;
    }

    .gridTable2 thead th {
        color: #fff;
        font-weight: 400;
        width: fit-content;
        padding: 3px 7px;
        white-space: nowrap;
    }

        .gridTable2 thead th:first-child {
            border-radius: 5px 0 0 0;
        }

        .gridTable2 thead th:last-child {
            border-radius: 0 5px 0 0;
        }

        .gridTable2 thead th:not(:first-child), gridTable2 thead th:not(:last-child) {
            border-left: 1px solid #eee;
        }

    .gridTable2 tbody td:last-child {
        border-right: 1px solid #eee;
    }

    .gridTable2 tbody tr:last-child {
        border-bottom: 1px solid #eee;
    }

    .gridTable2 tbody tr:hover {
        cursor: pointer;
        background-color: #99999925;
    }

    .gridTable2 tbody tr:nth-child(odd) {
        background-color: #00000008;
    }

    .gridTable2 tbody td:first-child {
        white-space: nowrap;
        padding: 3px 7px;
        border-left: 1px solid #eee;
    }

    .gridTable2 tbody td:not(:first-child) {
        padding: 3px 7px;
        white-space: nowrap;
        border-left: 1px solid #eee;
    }

    .gridTable2 tbody tr {
        border-top: 1px solid #eee;
        width: fit-content;
    }

    .gridTable2 tbody td {
        width: fit-content;
        white-space: break-spaces !important;
    }

.layoutTable {
}

    .layoutTable tbody td {
        white-space: nowrap;
    }


.siteNotesTable {
}

    .siteNotesTable tr:hover {
        background-color: #00009908;
        cursor: pointer;
    }

    .siteNotesTable tr:nth-child(even) {
        background-color: #99999910;
    }

    .siteNotesTable td:first-child {
        padding: 4px;
        border-top: 1px solid #eee;
    }

    .siteNotesTable td:not(:first-child) {
        padding: 4px;
        border-top: 1px solid #eee;
        border-left: 1px solid #eee;
    }

.layoutTable {
    width: fit-content;
}

    .layoutTable tbody td {
        padding: 3px;
        white-space: nowrap;
    }

.examTable {
    margin-top: 1vh;
    /*margin-left: 1vw;*/
    min-width: 20vw;
}

    .examTable thead tr {
        background-color: #007bff;
        border-top: 1px solid #ddd;
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
    }

    .examTable thead td {
        border-left: 1px solid #ddd;
        font-weight: 500;
        padding: 2px 5px;
        color: #fff;
    }

    .examTable tbody tr {
        border-top: 1px solid #ddd;
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
    }

        .examTable tbody tr:hover {
            background-color: #00009908;
            cursor: pointer;
            width: fit-content;
        }

        .examTable tbody tr:nth-child(even) {
            background-color: #99999910;
            width: fit-content;
        }

        .examTable tbody tr:last-child {
            border-bottom: 1px solid #ddd;
        }

    .examTable tbody td {
        white-space: nowrap;
        padding: 2px 5px;
        border-left: 1px solid #ddd;
    }


.card {
    width: 100% !important;
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    width: 39vw;
}

.card-student {
    width: fit-content !important;
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    width: 39vw;
}

.row-label-input-checkbox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.row-label-input-noleftmargin {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
}

.no-suggestions {
    color: #999;
    padding: 0.5rem;
}

/* autocomplete style*/
.suggestions {
    position: absolute;
    /*bottom:252px;*/
    /*left: 161px;*/
    z-index: 9999;
    border: 1px solid #ccc;
    border-top-width: 0;
    list-style: none;
    margin-top: 1px;
    margin-left: 1px;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    min-width: 210px;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.3);
}

    .suggestions li {
        padding: 0.5rem;
        background-color: #f7f8fc;
        color: #000;
        font-weight: 500;
    }

        .suggestion-active,
        .suggestions li:hover {
            background-color: #ddd;
            color: #000;
            cursor: pointer;
            /*font-weight: 600;*/
        }

        .suggestions li:not(:last-of-type) {
            border-bottom: 1px solid #999;
        }

@media only screen and (max-width: 768px) {
    .suggestions {
        position: absolute;
        left: 121px;
        z-index: 9999;
        border: 1px solid #ccc;
        border-top-width: 0;
        list-style: none;
        margin-top: 49px;
        max-height: 143px;
        overflow-y: auto;
        padding-left: 0;
        width: calc(192px + 1rem);
        border-radius: 0 0 8px 8px;
        box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.3);
    }
}


.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10555;
    /*display: none;*/
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}

.inventory-modal {
    /* width: 50% !important;
    max-width: unset !important;
    min-width: 600px !important; */
}

.inventory-modal2 {
    /* width: 50% !important;
    max-width: unset !important;
    min-width: 600px !important; */
    background-color: #0000ff11;
    border: 1px solid #f7f7f7;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}

.modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        transition: none;
    }
}

.modal.show .modal-dialog {
    transform: none;
}

.modal.modal-static .modal-dialog {
    transform: scale(1.02);
}

.modal-dialog-scrollable {
    height: calc(100% - 1rem);
}

    .modal-dialog-scrollable .modal-content {
        max-height: 100%;
        overflow: hidden;
    }

    .modal-dialog-scrollable .modal-body {
        overflow-y: auto;
    }

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

    .modal-backdrop.fade {
        opacity: 0;
    }

    .modal-backdrop.show {
        opacity: 0.5;
    }

.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

    .modal-header .btn-close {
        padding: 0.5rem 0.5rem;
        margin: -0.5rem -0.5rem -0.5rem auto;
    }

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
}

.width-100-percent {
    width: 100%;
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
}

    .modal-footer > * {
        margin: 0.25rem;
    }

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }

    .modal-dialog-scrollable {
        height: calc(100% - 3.5rem);
    }

    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    }

    .modal-sm {
        max-width: 300px;
    }
}

@media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
        max-width: 800px;
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1140px;
    }
}

.modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
}

    .modal-fullscreen .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }

    .modal-fullscreen .modal-header {
        border-radius: 0;
    }

    .modal-fullscreen .modal-body {
        overflow-y: auto;
    }

    .modal-fullscreen .modal-footer {
        border-radius: 0;
    }

@media (max-width: 575.98px) {
    .modal-fullscreen-sm-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }

        .modal-fullscreen-sm-down .modal-content {
            height: 100%;
            border: 0;
            border-radius: 0;
        }

        .modal-fullscreen-sm-down .modal-header {
            border-radius: 0;
        }

        .modal-fullscreen-sm-down .modal-body {
            overflow-y: auto;
        }

        .modal-fullscreen-sm-down .modal-footer {
            border-radius: 0;
        }
}

@media (max-width: 767.98px) {
    .modal-fullscreen-md-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }

        .modal-fullscreen-md-down .modal-content {
            height: 100%;
            border: 0;
            border-radius: 0;
        }

        .modal-fullscreen-md-down .modal-header {
            border-radius: 0;
        }

        .modal-fullscreen-md-down .modal-body {
            overflow-y: auto;
        }

        .modal-fullscreen-md-down .modal-footer {
            border-radius: 0;
        }
}

@media (max-width: 991.98px) {
    .modal-fullscreen-lg-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }

        .modal-fullscreen-lg-down .modal-content {
            height: 100%;
            border: 0;
            border-radius: 0;
        }

        .modal-fullscreen-lg-down .modal-header {
            border-radius: 0;
        }

        .modal-fullscreen-lg-down .modal-body {
            overflow-y: auto;
        }

        .modal-fullscreen-lg-down .modal-footer {
            border-radius: 0;
        }
}

@media (max-width: 1199.98px) {
    .modal-fullscreen-xl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }

        .modal-fullscreen-xl-down .modal-content {
            height: 100%;
            border: 0;
            border-radius: 0;
        }

        .modal-fullscreen-xl-down .modal-header {
            border-radius: 0;
        }

        .modal-fullscreen-xl-down .modal-body {
            overflow-y: auto;
        }

        .modal-fullscreen-xl-down .modal-footer {
            border-radius: 0;
        }
}

@media (max-width: 1399.98px) {
    .modal-fullscreen-xxl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }

        .modal-fullscreen-xxl-down .modal-content {
            height: 100%;
            border: 0;
            border-radius: 0;
        }

        .modal-fullscreen-xxl-down .modal-header {
            border-radius: 0;
        }

        .modal-fullscreen-xxl-down .modal-body {
            overflow-y: auto;
        }

        .modal-fullscreen-xxl-down .modal-footer {
            border-radius: 0;
        }
}

.popout {
    animation: popout .40s ease;
    -webkit-animation: popout 1s ease;
}

@keyframes popout {
    from {
        transform: scale(0)
    }

    80% {
        transform: scale(1.1)
    }

    to {
        transform: scale(1)
    }
}

@-webkit-keyframes popout {
    from {
        -webkit-transform: scale(0)
    }

    80% {
        -webkit-transform: scale(1.1)
    }

    to {
        -webkit-transform: scale(1)
    }
}

.et-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 10px;
    padding: 25px;
    min-width: 20vw;
    min-height: 6vw;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.5);
    border: 0;
    outline: none;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000e1a80;
}

/*button {
    margin-top: 10px;
    margin-bottom: 20px;
    white-space: nowrap;
    padding: 3px 20px;
    background: #0074c8;
    color: #fff;
    border-radius: 4px !important;
    border: 1px solid #007bff;
    cursor: pointer
}*/
.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: inherit !important;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

label {
    margin-bottom: 0 !important;
}

.form-control-select {
    margin-bottom: 10px;
    display: block;
    min-width: 20vw;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: inherit !important;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.table-responsive {
    display: block !important;
    /*width: fit-content !important;*/
    min-width: 100% !important;
    overflow-x: auto !important;
    -webkit-overflow-scrolling: touch !important;
    max-height: 57vh !important;
}

.select-label {
    vertical-align: middle;
    padding-right: 5px;
    margin-bottom: 10px;
}

.form-control-checkbox {
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    accent-color: #1b6ec2 !important;
    margin-bottom: 2px;
    margin-right: 3px;
    transform: scale(1.3);
}

.form-control-checkbox-disabled {
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    accent-color: #1b6ec2 !important;
    margin-bottom: 2px;
    margin-right: 3px;
    transform: scale(1.3);
    pointer-events: none;
}


.deleteUploadCell {
    display: flex;
    justify-content: center;
}

    .deleteUploadCell:hover {
        background-color: rgba(255,0,0,0.05);
    }

.studentTable {
    width: 100%;
}

    .studentTable tbody tr {
        display: flex;
        justify-content: flex-end;
    }

    .studentTable tbody td {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 50%;
        margin-bottom: 5px;
        padding-right: 5px;
        white-space: nowrap;
    }

        .studentTable tbody td:first-child {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            /* padding-bottom: 5px;*/
        }

.distanceNearTable {
    width: 450px;
}

    .distanceNearTable tbody tr {
        display: flex;
        justify-content: flex-start;
    }

    .distanceNearTable tbody td {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 3px;
        white-space: nowrap;
    }

        .distanceNearTable tbody td input[type=text] {
            width: 80px;
        }

.replacementLayoutTables {
    width: fit-content;
}

    .replacementLayoutTables tbody td {
        padding: 5px;
    }

        .replacementLayoutTables tbody td:first-child {
            text-align: right;
            white-space: nowrap;
        }

        .replacementLayoutTables tbody td input[type=text], .replacementLayoutTables tbody td select, .replacementLayoutTables tbody td input[type=date] {
            width: 300px;
        }


.checkboxLabel {
    display: flex;
    align-items: center;
    margin-left: 5px !important;
    margin-top: 15px;
}

.site-checkbox {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-left: 15px !important;
}

.displayLabelTitle {
    font-size: 14px;
    padding: 0;
    margin: 0;
    margin-bottom: 2px;
}

    .displayLabelTitle label {
        font-size: 16px;
        font-weight: 500;
    }

.studentScreeningTable {
    width: fit-content;
    max-width: 20vw;
}

    .studentScreeningTable tbody td {
        padding: 2px 3px;
        width: fit-content;
        white-space: nowrap;
        font-size: 1.2em;
        text-align: right;
        font-weight: 300;
        vertical-align: middle;
    }

        .studentScreeningTable tbody td:last-child {
            padding: 2px 3px;
            font-weight: 500;
            text-align: left;
            font-size: 1.2em;
            vertical-align: middle;
        }

.spinner-cell {
    border-left: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 30vh;
    position: absolute;
    top: 20%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px
}

button, .button, input[type=button] {
    white-space: nowrap !important;
    padding: 5px 20px !important;
    width: fit-content !important;
    background-color: #0074c8 !important;
    color: #fff !important;
}

    button:disabled, .button:disabled, input[type=button]:disabled {
        pointer-events: none;
        opacity: 0.6;
    }

    button:hover, .button:hover, input[type=button]:hover {
        cursor: pointer;
        background-color: #0064c4 !important;
    }

.preExamTable {
    width: fit-content;
    margin-bottom: 20px;
}

    .preExamTable tr {
        width: 100%;
    }

    .preExamTable td:first-child {
        text-align: right;
        vertical-align: middle;
        white-space: nowrap;
        padding: 3px;
        font-weight: 300;
        font-size: 1.2em;
    }

    .preExamTable td:not(first-child) {
        text-align: left;
        vertical-align: middle;
        white-space: nowrap;
        padding: 3px;
        font-weight: 500;
        font-size: 1.2em;
    }

    .preExamTable td input {
        font-weight: 400 !important;
        font-size: 14px !important;
    }

.tinyInput {
    width: 45px !important;
}

.smallInput {
    width: 60px !important;
}

.timeInput {
    width: 130px !important;
}

.examMeasurementsTable {
    width: 100%;
}

.examTextAreaTable {
    width: 100%;
}

    .examTextAreaTable td:first-child {
        padding-top: 6px;
        padding-right: 5px;
        text-align: right;
        vertical-align: top;
        width: 25%;
        white-space: nowrap;
    }

    .examTextAreaTable td:not(first-child) {
        text-align: left;
        vertical-align: middle;
        width: 75%;
        white-space: nowrap;
    }

.examTable1 {
    margin-top: 25px;
    margin-bottom: 25px;
    width: fit-content;
}

    .examTable1 tbody tr {
        line-height: 1.5em;
    }

    .examTable1 tbody td {
        white-space: nowrap;
    }

.margin-left-5 {
    margin-left: 5px !important;
}

.examCheckboxes1 {
    width: 23vw;
    white-space: nowrap;
    margin-top: 10px;
}

    .examCheckboxes1 tbody tr {
        width: fit-content;
        white-space: nowrap;
    }

    .examCheckboxes1 tbody td:nth-child(even) {
        width: fit-content;
        text-align: left;
        white-space: nowrap;
        padding-right: 10px;
        padding-left: 5px;
        margin-left: 0;
    }

    .examCheckboxes1 tbody td:nth-child(5) {
        border-left: 1px solid #ccc;
        padding-left: 20px;
    }

    .examCheckboxes1 tbody tr:first-child td:nth-child(3) {
        border-left: 1px solid #ccc;
        padding-left: 20px;
    }

.examTable2 {
}

    .examTable2 tbody td {
        padding-bottom: 7px;
        padding-right: 5px;
    }
